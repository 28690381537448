import * as types from 'core/redux/types/quiz'

/**
 * This action creator uses `meta` for handling side effects inside component.
 */
export const requestCreateQuiz = (data, meta) => ({
  type: types.REQUEST_CREATE_QUIZ,
  data,
  meta
})

export const createQuizSuccess = data => ({
  type: types.CREATE_QUIZ_SUCCESS,
  data
})

export const createQuizFailed = error => ({
  type: types.CREATE_QUIZ_FAILED,
  error
})

/**
 * Action creators for getting the list quizzes.
 */
export const requestGetQuizzes = (data, meta) => ({
  type: types.REQUEST_GET_QUIZZES,
  data,
  meta
})

export const getQuizzesSuccess = data => ({
  type: types.GET_QUIZZES_SUCCESS,
  data
})

export const getQuizzesFailed = error => ({
  type: types.GET_QUIZZES_FAILED,
  error
})

/**
 * Action creators for getting the list of templates.
 */
export const requestGetTemplates = (data, meta) => ({
  type: types.REQUEST_GET_TEMPLATES,
  data,
  meta
})

export const getTemplatesSuccess = data => ({
  type: types.GET_TEMPLATES_SUCCESS,
  data
})

export const getTemplatesFailed = error => ({
  type: types.GET_TEMPLATES_FAILED,
  error
})

/**
 * Action creators for updating the quiz.
 */
export const requestUpdateQuiz = (id, data, meta) => ({
  type: types.REQUEST_UPDATE_QUIZ,
  id,
  data,
  meta
})

export const updateQuizSuccess = data => ({
  type: types.UPDATE_QUIZ_SUCCESS,
  data
})

export const updateQuizFailed = error => ({
  type: types.UPDATE_QUIZ_FAILED,
  error
})

/**
 * Action creators for fetching single quiz.
 */
export const requestGetQuizById = (id, meta) => ({
  type: types.REQUEST_GET_QUIZ_BY_ID,
  id,
  meta
})

export const getQuizByIdSuccess = data => ({
  type: types.GET_QUIZ_BY_ID_SUCCESS,
  data
})

export const getQuizByIdFailed = error => ({
  type: types.GET_QUIZ_BY_ID_FAILED,
  error
})

/**
 * Action creators for creating a quiz score function
 */
export const requestCreateScoreEmailReply = (data, meta) => ({
  type: types.REQUEST_CREATE_SCORE_EMAIL_REPLY,
  data,
  meta
})

export const createScoreEmailReplySuccess = data => ({
  type: types.CREATE_SCORE_EMAIL_REPLY_SUCCESS,
  data
})

export const createScoreEmailReplyFailed = error => ({
  type: types.CREATE_SCORE_EMAIL_REPLY_FAILED,
  error
})

/**
 * Action creators for creating a quiz
 */
export const requestCreateQuestion = (data, meta) => ({
  type: types.REQUEST_CREATE_QUESTION,
  data,
  meta
})

export const createQuestionSuccess = data => ({
  type: types.CREATE_QUESTION_SUCCESS,
  data
})

export const createQuestionFailed = error => ({
  type: types.CREATE_QUESTION_FAILED,
  error
})

export const requestCreateGroupedQuestionWithSettings = (data, meta) => ({
  type: types.REQUEST_CREATE_GROUPED_QUESTION_WITH_SETTINGS,
  data,
  meta
})

export const createGroupedQuestionWithSettingsSuccess = data => ({
  type: types.CREATE_GROUPED_QUESTION_WITH_SETTINGS_SUCCESS,
  data
})

export const createGroupedQuestionWithSettingsFailed = error => ({
  type: types.CREATE_GROUPED_QUESTION_WITH_SETTINGS_FAILED,
  error
})

export const requestCreateGroupedQuestion = (data, meta) => ({
  type: types.REQUEST_CREATE_GROUPED_QUESTION,
  data,
  meta
})

export const createGroupedQuestionSuccess = data => ({
  type: types.CREATE_GROUPED_QUESTION_SUCCESS,
  data
})

export const createGroupedQuestionFailed = error => ({
  type: types.CREATE_GROUPED_QUESTION_FAILED,
  error
})

export const requestUpdateGroupedQuestionSettings = (data, meta) => ({
  type: types.REQUEST_UPDATE_GROUPED_QUESTION_SETTINGS,
  data,
  meta
})

export const updateGroupedQuestionSettingsSuccess = data => ({
  type: types.UPDATE_GROUPED_QUESTION_SETTINGS_SUCCESS,
  data
})

export const updateGroupedQuestionSettingsFailed = error => ({
  type: types.UPDATE_GROUPED_QUESTION_SETTINGS_FAILED,
  error
})

export const requestDeleteQuestion = (data, meta) => ({
  type: types.REQUEST_DELETE_QUESTION,
  data,
  meta
})

export const deleteQuestionSuccess = data => ({
  type: types.DELETE_QUESTION_SUCCESS,
  data
})

export const deleteQuestionFailed = error => ({
  type: types.DELETE_QUESTION_FAILED,
  error
})

export const requestUpdateQuestion = (data, meta) => ({
  type: types.REQUEST_UPDATE_QUESTION,
  data,
  meta
})

export const updateQuestionSuccess = data => ({
  type: types.UPDATE_QUESTION_SUCCESS,
  data
})

export const updateQuestionFailed = error => ({
  type: types.UPDATE_QUESTION_FAILED,
  error
})

export const requestUpdateQuestionWithAnswers = (data, meta) => ({
  type: types.REQUEST_UPDATE_QUESTION_WITH_ANSWERS,
  data,
  meta
})

export const updateQuestionWithAnswersSuccess = data => ({
  type: types.UPDATE_QUESTION_WITH_ANSWERS_SUCCESS,
  data
})

export const updateQuestionWithAnswersFailed = error => ({
  type: types.UPDATE_QUESTION_WITH_ANSWERS_FAILED,
  error
})

/**
 * Action creators for creating an answer to question
 */
export const requestCreateAnswer = (data, meta) => ({
  type: types.REQUEST_CREATE_ANSWER,
  data,
  meta
})

export const createAnswerSuccess = data => ({
  type: types.CREATE_ANSWER_SUCCESS,
  data
})

export const createAnswerFailed = error => ({
  type: types.CREATE_ANSWER_FAILED,
  error
})

export const requestDeleteAnswer = (data, meta) => ({
  type: types.REQUEST_DELETE_ANSWER,
  data,
  meta
})

export const deleteAnswerSuccess = data => ({
  type: types.DELETE_ANSWER_SUCCESS,
  data
})

export const deleteAnswerFailed = error => ({
  type: types.DELETE_ANSWER_FAILED,
  error
})

export const requestUpdateAnswer = (data, meta) => ({
  type: types.REQUEST_UPDATE_ANSWER,
  data,
  meta
})

export const updateAnswerSuccess = data => ({
  type: types.UPDATE_ANSWER_SUCCESS,
  data
})

export const updateAnswerFailed = error => ({
  type: types.UPDATE_ANSWER_FAILED,
  error
})

/**
 * Action creators for saving all email score for question
 */
export const requestSaveAllEmailScoring = (data, meta) => ({
  type: types.REQUEST_SAVE_ALL_EMAIL_SCORING,
  data,
  meta
})

export const saveAllEmailScoringSuccess = data => ({
  type: types.SAVE_ALL_EMAIL_SCORING_SUCCESS,
  data
})

export const saveAllEmailScoringFailed = error => ({
  type: types.SAVE_ALL_EMAIL_SCORING_FAILED,
  error
})

/**
 * Action creators for creating an email score for question
 */
export const requestUpdateEmailScore = (data, meta) => ({
  type: types.REQUEST_UPDATE_EMAIL_FOR_SCORING,
  data,
  meta
})

export const updateEmailForScoringSuccess = data => ({
  type: types.UPDATE_EMAIL_FOR_SCORING_SUCCESS,
  data
})

export const updateEmailForScoringFailed = error => ({
  type: types.UPDATE_EMAIL_FOR_SCORING_FAILED,
  error
})

/**
 * Action deleting single email score
 */
export const requestDeleteSingleEmailScore = (data, meta) => ({
  type: types.REQUEST_DELETE_SINGLE_EMAIL_FOR_SCORING,
  data,
  meta
})

export const deleteSingleEmailForScoringSuccess = data => ({
  type: types.DELETE_SINGLE_EMAIL_FOR_SCORING_SUCCESS,
  data
})

export const deleteSingleEmailForScoringFailed = error => ({
  type: types.DELETE_SINGLE_EMAIL_FOR_SCORING_FAILED,
  error
})

/**
 * Action deleting all emails score
 */
export const requestDeleteAllEmailScore = (data, meta) => ({
  type: types.REQUEST_DELETE_ALL_EMAIL_FOR_SCORING,
  data,
  meta
})

export const deleteAllEmailForScoringSuccess = data => ({
  type: types.DELETE_ALL_EMAIL_FOR_SCORING_SUCCESS,
  data
})

export const deleteAllEmailForScoringFailed = error => ({
  type: types.DELETE_ALL_EMAIL_FOR_SCORING_FAILED,
  error
})

/**
 * Action creators for creating page title in quiz.
 */
export const requestCreateTitle = (data, meta) => ({
  type: types.REQUEST_CREATE_TITLE,
  data,
  meta
})

export const createTitleSuccess = data => ({
  type: types.CREATE_TITLE_SUCCESS,
  data
})

export const createTitleFailed = error => ({
  type: types.CREATE_TITLE_FAILED,
  error
})

export const requestDeleteTitle = (data, meta) => ({
  type: types.REQUEST_DELETE_TITLE,
  data,
  meta
})

export const deleteTitleSuccess = data => ({
  type: types.DELETE_TITLE_SUCCESS,
  data
})

export const deleteTitleFailed = error => ({
  type: types.DELETE_TITLE_FAILED,
  error
})

/**
 * Used for updating title and subtitle of the page.
 */
export const requestUpdateTitle = (data, meta) => ({
  type: types.REQUEST_UPDATE_TITLE,
  data,
  meta
})

export const updateTitleSuccess = data => ({
  type: types.UPDATE_TITLE_SUCCESS,
  data
})

export const updateTitleFailed = error => ({
  type: types.UPDATE_TITLE_FAILED,
  error
})

/**
 * Used for updating all questions, answers and titles in the quiz.
 */
export const requestBulkQuizUpdate = (data, meta) => ({
  type: types.REQUEST_BULK_QUIZ_UPDATE,
  data,
  meta
})

export const bulkQuizUpdateSuccess = data => ({
  type: types.BULK_QUIZ_UPDATE_SUCCESS,
  data
})

export const bulkQuizUpdateFailed = error => ({
  type: types.BULK_QUIZ_UPDATE_FAILED,
  error
})

/**
 * Used for deleting quiz.
 */
export const requestDeleteQuiz = (id, meta) => ({
  type: types.REQUEST_DELETE_QUIZ,
  id,
  meta
})

export const deleteQuizSuccess = id => ({
  type: types.DELETE_QUIZ_SUCCESS,
  id
})

export const deleteQuizFailed = error => ({
  type: types.DELETE_QUIZ_FAILED,
  error
})

/**
 * Used for duplicating quiz.
 */
export const requestDuplicateQuiz = (id, data, meta) => ({
  type: types.REQUEST_DUPLICATE_QUIZ,
  id,
  data,
  meta
})

export const duplicateQuizSuccess = data => ({
  type: types.DUPLICATE_QUIZ_SUCCESS,
  data
})

export const duplicateQuizFailed = error => ({
  type: types.DUPLICATE_QUIZ_FAILED,
  error
})

/**
 * Used in respondent flow.
 */
export const requestGetQuizForFlow = (id, config, meta) => ({
  type: types.REQUEST_GET_QUIZ_FOR_FLOW,
  id,
  config,
  meta
})

export const getQuizForFlowSuccess = data => ({
  type: types.GET_QUIZ_FOR_FLOW_SUCCESS,
  data
})

export const getQuizForFlowFailed = error => ({
  type: types.GET_QUIZ_FOR_FLOW_FAILED,
  error
})

/**
 * Used for creating respondent for quiz flow.
 */
export const requestCreateRespondent = (data, meta) => ({
  type: types.REQUEST_CREATE_RESPONDENT,
  data,
  meta
})

export const createRespondentSuccess = data => ({
  type: types.CREATE_RESPONDENT_SUCCESS,
  data
})

export const createRespondentFailed = error => ({
  type: types.CREATE_RESPONDENT_FAILED,
  error
})

/**
 * Used for creating respondent by coupon for quiz flow.
 */
export const requestCreateRespondentByCoupon = (id, uid, meta) => ({
  type: types.REQUEST_CREATE_RESPONDENT_BY_COUPON,
  id,
  uid,
  meta
})

export const createRespondentByCouponSuccess = data => ({
  type: types.CREATE_RESPONDENT_BY_COUPON_SUCCESS,
  data
})

export const createRespondentByCouponFailed = error => ({
  type: types.CREATE_RESPONDENT_BY_COUPON_FAILED,
  error
})

/**
 * Used for get quiz flow by respondent uid.
 */
export const requestGetQuizByRespondentUid = (data, meta) => ({
  type: types.REQUEST_GET_QUIZ_BY_RESPONDENT_UID,
  data,
  meta
})

export const getQuizByRespondentUidSuccess = data => ({
  type: types.GET_QUIZ_BY_RESPONDENT_UID_SUCCESS,
  data
})

export const getQuizByRespondentUidFailed = errors => ({
  type: types.GET_QUIZ_BY_RESPONDENT_UID_FAILED,
  errors
})

/**
 * Used for bulk post of answers.
 */
export const requestPostRespondentAnswers = (uid, data, meta) => ({
  type: types.REQUEST_POST_RESPONDENT_ANSWERS,
  uid,
  data,
  meta
})

export const postRespondentAnswersSuccess = data => ({
  type: types.POST_RESPONDENT_ANSWERS_SUCCESS,
  data
})

export const postRespondentAnswersFailed = error => ({
  type: types.POST_RESPONDENT_ANSWERS_FAILED,
  error
})

/**
 * Used for updating respondent info.
 */
export const requestUpdateRespondent = (uid, data, meta) => ({
  type: types.REQUEST_UPDATE_RESPONDENT,
  uid,
  data,
  meta
})

export const updateRespondentSuccess = data => ({
  type: types.UPDATE_RESPONDENT_SUCCESS,
  data
})

export const updateRespondentFailed = error => ({
  type: types.UPDATE_RESPONDENT_FAILED,
  error
})

/**
 * Used for fetching quiz statistics. Most probably should be moved to separate
 * `statistics` module.
 */
export const requestGetStatistics = (data, meta) => ({
  type: types.REQUEST_GET_STATISTICS,
  data,
  meta
})

export const getStatisticsSuccess = data => ({
  type: types.GET_STATISTICS_SUCCESS,
  data
})

export const getStatisticsFailed = error => ({
  type: types.GET_STATISTICS_FAILED,
  error
})

/**
 * Used for fetching quiz when paying for it.
 */
export const requestGetQuizForTransaction = (id, meta) => ({
  type: types.REQUEST_GET_QUIZ_FOR_TRANSACTION,
  id,
  meta
})

export const getQuizForTransactionSuccess = data => ({
  type: types.GET_QUIZ_FOR_TRANSACTION_SUCCESS,
  data
})

export const getQuizForTransactionFailed = error => ({
  type: types.GET_QUIZ_FOR_TRANSACTION_FAILED,
  error
})

/**
 * Used for fetching and validating paid quiz.
 */
export const requestGetPaidQuizById = (id, uid, meta) => ({
  type: types.REQUEST_GET_PAID_QUIZ_BY_ID,
  id,
  uid,
  meta
})

export const getPaidQuizByIdSuccess = data => ({
  type: types.GET_PAID_QUIZ_BY_ID_SUCCESS,
  data
})

export const getPaidQuizByIdFailed = error => ({
  type: types.GET_PAID_QUIZ_BY_ID_FAILED,
  error
})

/**
 * Used for fetching list of respondents (contacts).
 */
export const requestGetRespondents = (config, meta) => ({
  type: types.REQUEST_GET_RESPONDENTS,
  config,
  meta
})

export const getRespondentsSuccess = data => ({
  type: types.GET_RESPONDENTS_SUCCESS,
  data
})

export const getRespondentsFailed = error => ({
  type: types.GET_RESPONDENTS_FAILED,
  error
})

/**
 * Used on respondent details page, showing respondent quiz answers.
 */
export const requestGetRespondentQuiz = (id, meta) => ({
  type: types.REQUEST_GET_RESPONDENT_QUIZ,
  id,
  meta
})

export const getRespondentQuizSuccess = data => ({
  type: types.GET_RESPONDENT_QUIZ_SUCCESS,
  data
})

export const getRespondentQuizFailed = error => ({
  type: types.GET_RESPONDENT_QUIZ_FAILED,
  error
})

/**
 * Used for removal of the respondent in contacts list.
 */
export const requestDeleteRespondent = (id, meta) => ({
  type: types.REQUEST_DELETE_RESPONDENT,
  id,
  meta
})

export const deleteRespondentSuccess = id => ({
  type: types.DELETE_RESPONDENT_SUCCESS,
  id
})

export const deleteRespondentFailed = error => ({
  type: types.DELETE_RESPONDENT_FAILED,
  error
})

/**
 * Used in contacts list to send email analysis manually.
 */
export const requestSendAnalysisByEmail = (data, meta) => ({
  type: types.REQUEST_SEND_ANALYSIS_BY_EMAIL,
  data,
  meta
})

export const sendAnalysisByEmailSuccess = data => ({
  type: types.SEND_ANALYSIS_BY_EMAIL_SUCCESS,
  data
})

export const sendAnalysisByEmailFailed = error => ({
  type: types.SEND_ANALYSIS_BY_EMAIL_FAILED,
  error
})

/**
 * Used for resetting quiz statistics.
 */
export const requestResetQuizStatistics = (id, meta) => ({
  type: types.REQUEST_RESET_QUIZ_STATISTICS,
  id,
  meta
})

export const resetQuizStatisticsSuccess = data => ({
  type: types.RESET_QUIZ_STATISTICS_SUCCESS,
  data
})

export const resetQuizStatisticsFailed = error => ({
  type: types.RESET_QUIZ_STATISTICS_FAILED,
  error
})

/**
 * Used for resetting quiz evaluation.
 */
export const requestResetQuizEvaluation = (id, meta) => ({
  type: types.REQUEST_RESET_QUIZ_EVALUATION,
  id,
  meta
})

export const resetQuizEvaluationSuccess = data => ({
  type: types.RESET_QUIZ_EVALUATION_SUCCESS,
  data
})

export const resetQuizEvaluationFailed = error => ({
  type: types.RESET_QUIZ_EVALUATION_FAILED,
  error
})

/**
 * Used for get statistic evaluation.
 */
export const requestStatisticsEvaluationById = (id, meta) => ({
  type: types.REQUEST_GET_STATISTIC_EVALUATION_BY_ID,
  id,
  meta
})

export const getStatisticsEvaluationSuccess = data => ({
  type: types.GET_STATISTIC_EVALUATION_SUCCESS,
  data
})

export const getStatisticsEvaluationFailed = error => ({
  type: types.GET_STATISTIC_EVALUATION_FAILED,
  error
})

/**
 * Used for setting the quiz as started.
 */
export const requestMarkQuizAsStarted = (data, meta) => ({
  type: types.REQUEST_MARK_QUIZ_AS_STARTED,
  data,
  meta
})

export const markQuizAsStartedSuccess = (data, meta) => ({
  type: types.MARK_QUIZ_AS_STARTED_SUCCESS,
  data,
  meta
})

export const markQuizAsStartedFailed = error => ({
  type: types.MARK_QUIZ_AS_STARTED_FAILED,
  error
})
/**
 * Used for setting the quiz as finished.
 */
export const requestMarkQuizAsFinished = (uid, meta) => ({
  type: types.REQUEST_MARK_QUIZ_AS_FINISHED,
  uid,
  meta
})

export const markQuizAsFinishedSuccess = data => ({
  type: types.MARK_QUIZ_AS_FINISHED_SUCCESS,
  data
})

export const markQuizAsFinishedFailed = error => ({
  type: types.MARK_QUIZ_AS_FINISHED_FAILED,
  error
})

export const requestUpdateOptInStep = (id, data, meta) => ({
  type: types.REQUEST_UPDATE_OPT_IN_STEP,
  id,
  data,
  meta
})
export const updateOptInStepSuccess = data => ({
  type: types.UPDATE_OPT_IN_STEP_SUCCESS,
  data
})

export const updateOptInStepFailed = error => ({
  type: types.UPDATE_OPT_IN_STEP_FAILED,
  error
})

export const requestUpdateOptInInformationToGather = (id, data, meta) => ({
  type: types.REQUEST_UPDATE_OPT_IN_INFORMATION_TO_GATHER,
  id,
  data,
  meta
})

export const updateOptInInformationToGatherSuccess = data => ({
  type: types.UPDATE_OPT_IN_INFORMATION_TO_GATHER_SUCCESS,
  data
})

export const updateOptInInformationToGatherFailed = error => ({
  type: types.UPDATE_OPT_IN_INFORMATION_TO_GATHER_FAILED,
  error
})

export const requestCreateDefaultAnswersForGroupedQuestion = (data, meta) => ({
  type: types.REQUEST_CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION,
  data,
  meta
})

export const createDefaultAnswersForGroupedQuestionSuccess = data => ({
  type: types.CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_SUCCESS,
  data
})

export const createDefaultAnswersForGroupedQuestionFailed = error => ({
  type: types.CREATE_DEFAULT_ANSWERS_FOR_GROUPED_QUESTION_FAILED,
  error
})
