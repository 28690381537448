// TODO: Write documentation and unit tests for that. fri, 3 apr 2020, 11:04:20
// CEST

import {
  allPass,
  curry,
  equals,
  evolve,
  filter,
  find,
  groupBy,
  hasPath,
  head,
  identity,
  ifElse,
  insert,
  is,
  join,
  keys,
  map,
  mapObjIndexed,
  mergeRight,
  not,
  path,
  pathOr,
  pick,
  pipe,
  pluck,
  prop,
  propOr,
  propEq,
  reject,
  sort,
  sortBy,
  splitEvery,
  toPairs,
  trim,
  uniqBy,
  values
} from 'ramda'

import { noop, isTruthy, spreadPath } from 'ramda-adjunct'

import CountryRepository from 'utils/CountryRepository'
import { formatDate } from 'utils/date'
import {
  EMPTY_SELECT_VALUE,
  BASIC_OPT_IN_OPTIONS,
  OPT_IN_OPTIONS,
  OPT_IN_TYPES,
  QUESTION_TYPES,
  EMAIL_RECEIVER,
  USER_CURRENCY,
  CURRENCY_OPTIONS,
  QUESTION_TYPE_SHORT_NAMES,
  CONDITIONAL_LOGIC
} from 'constants/quiz'

import LanguageService from 'services/LanguageService'

const rewritePagination = perPage => items =>
  items.reduce(
    (payload, question, idx) => {
      payload.pageAcc =
        idx % perPage > 0 ? payload.pageAcc : payload.pageAcc + 1

      payload.items.push({
        id: question._id,
        page: payload.pageAcc
      })

      return payload
    },
    {
      items: [],
      pageAcc: 0
    }
  )

export const paginateQuestions = perPage => questions =>
  pipe(
    sortBy(prop('page')),
    rewritePagination(perPage),
    prop('items')
  )(questions)

/**
 * Used to determine the page that the question is going to be added to. Returns a tuple with two elements. 1. Whether the title should be created or not. 2. The number of the new page.
 */
export const pageToAdd = curry((perPage, questions) => {
  if (questions.length === 0) return [true, 1]

  if (questions.length % perPage === 0) {
    return [true, questions.length / perPage + 1]
  }

  if (questions.length % perPage !== 0) {
    return [false, Math.floor(questions.length / perPage + 1)]
  }

  return [false, 0]
})

const getGroupedQuestions = (id, gid) =>
  pipe(
    filter(
      allPass([
        propEq('type', QUESTION_TYPES.GROUPED),
        pipe(pathOr('', ['gid', '_id']), equals(gid)),
        pipe(propEq('_id', id), not)
      ])
    ),
    sort((a, b) => a.position - b.position)
  )

export const getOrganisedQuestions = questions =>
  questions
    ? pipe(
        uniqBy(
          ifElse(hasPath(['gid', '_id']), path(['gid', '_id']), prop('_id'))
        ),
        map(q => ({
          ...q,
          __type: 'QUESTION',
          groupedQuestions: getGroupedQuestions(
            q._id,
            pathOr('', ['gid', '_id'], q)
          )(questions)
        })),
        sort((a, b) => a.position - b.position)
      )(questions)
    : {}

// TODO: PLEASE DOCUMENT THIS.
export const buildQuestionsStructure = quiz => {
  const questions = getOrganisedQuestions(quiz?.questions)

  const titles = quiz.titles.map(t => ({ ...t, __type: 'TITLE' }))
  const grouped = groupBy(q => q.page)(questions)
  const groupedTitles = groupBy(t => t.page)(titles)

  return mapObjIndexed((value, key, obj) => {
    return grouped[key] ? value.concat(grouped[key]) : value
  })(groupedTitles)
}

export const isAddTitleDisabled = structureValues => {
  return pipe(
    find(arr => arr.length === 1),
    isTruthy
  )(structureValues)
}

export const toPercent = no => {
  return Number(Math.round(no * 100) / 100) + '%'
}

export const isExternalProviderActive = quiz => quiz?.marketingTool?.active

/**
 * Takes the data from the whole second step and prepares payload for bulk
 * update.
 */
export const mapFormValuesToPayload = values =>
  Object.keys(values).reduce(
    (result, key) => {
      const [entityType, questionId, answerId] = key.split('-')

      const entityPayload = {
        _id: entityType === 'answer' ? answerId : questionId,
        ...values[key]
      }

      if (entityType === 'answer') {
        entityPayload.score = parseInt(values[key].score)
      }

      result[entityType]?.push(entityPayload)

      return result
    },
    {
      answer: [],
      question: [],
      groupedQuestionSettings: [],
      title: [],
      rule: [],
      logic: [],
      scoreAnalysis: []
    }
  )

/**
 * This function maps data to updating functions. Used for bulk updates of
 * questions. Also handles the update for question settings (used with grouped
 * questions.)
 * @name pickEntitiesForUpdate
 * Added: Mon, 12 Oct 2020 11:59:43 +0200
 * @method
 * @static
 * @param {Object} payload
 * @param {Object} matrix an object where keys are types of entities to update
 * and values are updating functions.
 * @returns Promise[]
 */
export const pickEntitiesForUpdate = (payload, matrix) => {
  return pipe(
    keys,
    filter(k => payload[k].length > 0),
    map(k => {
      /**
       * Grouped questions have to be handled in a different way, because they
       * have a separate entity that holds information about question
       * "settings". Since we don't have to do bulk update on settings, first
       * element from the array has to be extracted.
       * Added: Mon, 12 Oct 2020 11:55:55 +0200
       */
      return k === 'groupedQuestionSettings'
        ? matrix[k](head(payload[k]))
        : matrix[k](payload[k])
    })
  )(payload)
}

// TODO: Write tests for that and refactor this, Jesus.
export const reorderQuestionsInPage = ({
  currentPage,
  destinationPage,
  id,
  currentPosition,
  desiredPosition,
  questions
}) => {
  const item = {
    _id: id,
    position: desiredPosition
  }
  return pipe(
    // If reoder happens in the same page, make sure to remove the question
    // from array, because it will be inserted anyway. This is to avoid duplicates.
    els =>
      currentPage === destinationPage ? reject(q => q._id === id)(els) : els,
    // Pick the important parts for the update.
    map(q => ({ _id: q._id, position: q.position })),
    // Handle special case when item has to be the first one.
    els => {
      if (desiredPosition === 1) {
        return insert(0, item, els)
      }

      return insert(desiredPosition - 1, item, els)
    },
    // Rewrite position for all items.
    els =>
      els.map(({ _id }, idx) => ({
        _id,
        position: idx + 1,
        page: parseInt(destinationPage)
      }))
  )(questions)
}

export const prepareOptInPayload = values =>
  pipe(
    toPairs,
    filter(([, value]) => !!value),
    pluck(0)
  )(values)

export const withoutStartPage = isStartPage => isStartPage === 'false'

export const sanitizeObject = obj => filter(identity)(obj)

/**
 * Picks information to gather options from quiz object. Since address is a
 * collection of options, it has to be flattened.
 * @name pickInformationToGather
 * @method
 * @static
 * @param {Object} quiz
 * @returns {Object}
 */
export const pickInformationToGather = pipe(
  pick(OPT_IN_OPTIONS),
  spreadPath(['address'])
)

export const extractRespondentDetails = pipe(
  pickInformationToGather,
  toPairs,
  sortBy(prop(0)),
  splitEvery(5)
)

export const extractBasicRespondentDetails = pipe(
  spreadPath(['address']),
  pick(BASIC_OPT_IN_OPTIONS),
  toPairs
)

export const getInformationValue =
  t =>
  ({
    type,
    value,
    websiteRenderer = noop,
    socialMediaProfileRenderer = noop
  }) => {
    switch (type) {
      case OPT_IN_TYPES.WEBSITE:
        return websiteRenderer({ type, value })
      case OPT_IN_TYPES.SOCIAL_MEDIA:
        return socialMediaProfileRenderer({ type, value })
      case OPT_IN_TYPES.DATE_OF_BIRTH:
        return formatDate(value)
      case OPT_IN_TYPES.COUNTRY:
        return CountryRepository.getCountries()[value]
      case OPT_IN_TYPES.AGE:
        return t('Common:informationToGather.age', { value })
      case OPT_IN_TYPES.GENDER:
        return t(
          `Common:informationToGather.genderOptions.${value.toLowerCase()}`
        )
      case OPT_IN_TYPES.SALUTATION:
        return t(
          `Common:informationToGather.salutationOptions.${value
            .replace(/\./, '')
            .toLowerCase()}`
        )
      default:
        return value
    }
  }

export const getRespondentName = pipe(
  pick(['firstName', 'lastName']),
  values,
  join(' '),
  trim
)

/**
 * Determines whether quiz has any information to gather. Used in calculating
 * the length of quiz stepper.
 * @name getInformationToGatherFactor
 * @method
 * @static
 * @param {Object} quiz
 * @param {Array} quiz.informationToGather
 * @returns {Number}
 */
export const getInformationToGatherFactor = quiz =>
  quiz?.informationToGather?.length > 0 ? 0 : 1

export const calculateConversionRate = ({ finished, all }) =>
  finished === 0 || all === 0 ? 0 : (finished * 100) / all

export const calculateAnswerScores = (score = 0, totalAvailableScore = 100) =>
  parseInt(score) === 0 ? 0 : (parseInt(score) * 100) / totalAvailableScore

export const setQuizLanguage = quiz => {
  return quiz?.language ? LanguageService.changeLanguage(quiz.language) : null
}

export const validateMaxLength = ({ value, maxLength, t }) => {
  if (value && value.length > maxLength /*validationExp.test(value)*/) {
    return t('Common:validation.tooLong', { count: maxLength })
  } else {
    return true
  }
}

/**
 * This validator is triggered only if a specific field from the form
 * (`isStartPage`) is set to `true`. In any other way, the value will be ignored
 * and the so the validation does not matter.
 * Added: Tue, 20 Oct 2020 10:54:38 +0200
 * @name validateStartPageField
 * @method
 * @static
 * @param {function} getValues A function that returns a map with form name/value pairs
 * @param {Object} config
 * @param {number} config.maxLength Max length of the validated value
 * @param {Object} config.model Model used for the form
 * @param {function} config.t Namespaced translation function
 * @returns {boolean}
 */
export const validateStartPageField =
  (getValues, { maxLength, isStartPage, t, required = true }) =>
  value => {
    if (isStartPage) {
      if (!value && required) {
        return t('Common:validation.required')
      }

      if (maxLength) {
        return validateMaxLength({ value, maxLength, t })
      }

      return true
    }

    return true
  }

export const validateNameField =
  ({ maxLength, t, required = true }) =>
  value => {
    value = value.trim()

    if (!value && required) {
      return t('Common:validation.required')
    }

    return validateMaxLength({ value, maxLength, t })
  }

export const stringifyAndRemoveKeys =
  (list = []) =>
  (obj = {}) => {
    return JSON.stringify(obj, (_, value) => {
      if (list.includes(value)) {
        return
      }

      return value
    })
  }

export const adaptRespondentPayload = pipe(
  evolve({
    [OPT_IN_TYPES.AGE]: v => (!isNaN(parseInt(v.trim())) ? parseInt(v) : ''),
    [OPT_IN_TYPES.DATE_OF_BIRTH]: d => +new Date(d)
  }),
  mapObjIndexed((value, _key, _obj) => {
    if (value === EMPTY_SELECT_VALUE) {
      return null
    }

    if (is(String)(value)) {
      return value.trim()
    }

    return value
  }),
  stringifyAndRemoveKeys([EMPTY_SELECT_VALUE, null, undefined, '']),
  JSON.parse
)

export const getQuestionTypeShortName = (t, questionType) =>
  QUESTION_TYPE_SHORT_NAMES(t).find(item => item.type === questionType)
    ?.label ?? ''

/**
 * TODO: Move to selector since this shouldn't recalculated on every render.
 * Added: Sat, 24 Oct 2020 12:01:37 +0200
 */

/**

 * TODO: Move to selector since this shouldn't recalculated on every render.
 * About ramda's sort function: https://stackoverflow.com/a/60030419/2420343

 * (...) it takes a comparator: a binary function which must return a number. If
 * the number is negative, the first argument comes ahead of the second one in
 * the result; if it's positive, the second argument comes ahead of the first
 * one; it it's zero, you express no preference, although most implementations
 * these days are stable, which means that the first argument would come ahead.
 * Added: Tue, 24 Nov 2020 10:37:09 +0100
 * @name groupAnswersByQuestionTitle
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Answers[]} answers
 * @returns {GroupedAnswers[]} groupedAnswers
 */
export const groupAnswersByQuestionTitle = pipe(
  sort((a, b) => {
    if (!isOtherAnswer(a) && isOtherAnswer(b)) {
      return -1
    }

    if (isOtherAnswer(a) && !isOtherAnswer(b)) {
      return 1
    }

    if (a.questionPage < b.questionPage) {
      return -1
    }

    if (a.questionPage > b.questionPage) {
      return 1
    }

    if (a.questionPosition < b.questionPosition) {
      return -1
    }

    if (a.questionPosition > b.questionPosition) {
      return 1
    }
  }),
  groupBy(prop('questionContent')),
  toPairs
)

export const shouldDisplayOptionalField = type => {
  return [QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.RADIO].includes(type)
}

export const shouldDisplayShowInEmailField = type => {
  return [QUESTION_TYPES.TEXT, QUESTION_TYPES.DATE].includes(type)
}

export const isQuestionOptional = question => question?.customAnswerAllowed

export const shouldRenderCustomAnswer = question =>
  isQuestionOptional(question) && shouldDisplayOptionalField(question?.type)

/**
 * Determines whether answer has been added by the user or not. Used in
 * respondent details page.
 * Added: Tue, 24 Nov 2020 09:35:46 +0100
 * @name isOtherAnswer
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} answer
 * @returns {boolean}
 */
export const isOtherAnswer = answer =>
  answer.isTextAnswer &&
  [QUESTION_TYPES.CHECKBOX, QUESTION_TYPES.RADIO].includes(answer.questionType)

/**
 * Returns localized answer content.
 * Added: Wed, 06 Jan 2021 10:57:13 +0100
 * @name getAnswerContent
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} answer
 * @param {Object} t
 * @returns {string}
 */
export const getAnswerContent = (answer, t) => {
  /**
   * Because grouped answer can be optional, `answerContent` still has to be
   * considered as nullable and fall back to "no answer" translation.
   * Added: Thu, 01 Apr 2021 16:28:44 +0200
   */
  if (answer?.questionType === QUESTION_TYPES.GROUPED) {
    return answer?.answerContent
      ? t(`Common:${answer.answerContent}`)
      : t('Common:noAnswer')
  }

  if (answer?.questionType === QUESTION_TYPES.FILE) {
    return propOr(t('Common:noAnswer'), 'fileId', answer)
  }

  if (answer?.answerContent) {
    return answer.answerContent
  }

  return t('Common:noAnswer')
}

export const hasInformationToGather = quiz => {
  return quiz?.informationToGather?.length > 0
}

export const hasNoInformationToGather = quiz => {
  return quiz?.informationToGather?.length === 0
}

export const hasInformationToGatherOfType = (quiz, type) => {
  return quiz?.informationToGather?.some(information => {
    return information.name === type
  })
}

export const isQuizEmpty = quiz =>
  quiz?.titles?.length === 0 && quiz?.questions?.length === 0

/**
 * Returns different amount of quizzes depending on the vendor's email used for
 * registration.
 * Added: Mon, 25 Jan 2021 11:13:55 +0100
 * @name getQuizzesListPageLimitByUserProfile
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} profile - User profile entity. Usually taken from the redux
 * store, after `requestGetProfile` is dispatched
 * @returns {number} limit of quizzes per page
 */
export const getQuizzesListPageLimitByUserProfile = profile => {
  return 100
}

export const getDefaultSalutationForQuiz = quiz => {
  switch (quiz?.language) {
    case 'German':
      return {
        other: 'Hallo',
        ms: 'Liebe',
        mr: 'Lieber'
      }
    case 'English':
      return {
        other: 'Hello',
        ms: 'Dear',
        mr: 'Dear'
      }
    default:
      return {
        other: 'Hello',
        ms: 'Dear',
        mr: 'Dear'
      }
  }
}

/**
 * Sorts quiz's `informationToGather` list in particular order. Expects `orderMatrix` that is going to be used for determining new informationToGather fields' order. See `OPT_IN_TYPE_ORDER` constant.
 * Added: Tue, 02 Feb 2021 18:35:14 +0100
 * Related issues: QUIZ-572
 * @name sortInformationToGather
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} orderMatrix expected field order, .e.g. `OPT_IN_TYPE_ORDER`
 * @param {Object} quiz
 * @returns {Object[]} informationToGather
 */
export const sortInformationToGather = (orderMatrix, quiz) => {
  return pipe(
    propOr([], 'informationToGather'),
    itg =>
      itg.reduce((prev, curr) => {
        prev[orderMatrix[curr.name]] = curr

        return prev
      }, []),
    filter(identity)
  )(quiz)
}

export const mapAttachmentUrlToField = obj => {
  try {
    const _parsed = JSON.parse(obj.attachmentUrl)

    return _parsed.originalUrl
  } catch {
    return obj?.attachmentUrl
  }
}

export const mapIconUrlToField = obj => {
  try {
    const _parsed = JSON.parse(obj.iconUrl)

    return _parsed.originalUrl
  } catch {
    return obj?.iconUrl
  }
}

/**
 * Added: Sat, 27 Feb 2021 11:51:51 +0100
 * @name getQuestionPanelId
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param {Object} question
 * @param {string} question._id
 * @returns {string}
 */
export const getQuestionPanelId = question => {
  return `panel-question-${question?._id}`
}

/**
 * Pick email for manual analysis sending.
 * Added: Thu, 08 Apr 2021 10:11:32 +0200
 * @name getResponseEmailByType
 * @author Rafał Wyszomirski <ralf@desmart.com>
 * @method
 * @static
 * @param
 * @returns
 */
export const getResponseEmailByType = ({ type, respondent, user }) => {
  switch (type) {
    case EMAIL_RECEIVER.VENDOR:
      return user.email
    case EMAIL_RECEIVER.RESPONDENT:
      return respondent?.email
    case EMAIL_RECEIVER.SENDER:
      return respondent?.quiz?.emailSettings?.email
    default:
      return user.email
  }
}

export const getStripePublisabelKey = userCurrency => {
  return userCurrency === USER_CURRENCY.EUR
    ? import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_EUR
    : import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY_USD
}

export const validateTextEditorMode = ({ t, value }) => {
  return value === '<p></p>' || value === '<p><br></p>'
    ? t('Common:validation.required')
    : true
}

export const getBrowserLanguage = () => {
  return window.navigator.userLanguage || window.navigator.language
}

export const defaultCurrencyValue = () => {
  const language = getBrowserLanguage()

  return language.includes('de')
    ? CURRENCY_OPTIONS[0].value // EUR for Germany
    : CURRENCY_OPTIONS[1].value // USD for the rest of the world
}

export const getCookie = cookieName => {
  try {
    const decodeCookie = decodeURIComponent(document.cookie).split('; ') // get decode cookies
    const searchedCookie = decodeCookie.filter(el =>
      el.includes(`${cookieName}=`)
        ? JSON.parse(el.replace(`${cookieName}=`, ''))
        : null
    ) // get looking cookie
    const objectToReturn = JSON.parse(
      searchedCookie[0].replace(`${cookieName}=`, '')
    ) // get object from looking cookie
    return objectToReturn
  } catch (error) {
    return null
  }
}

export const setInformationToGatherOrder = (orderList, entries) => {
  const tempObjOfArr = [...entries]
  const sorted = []

  orderList.map(orderName => {
    tempObjOfArr.map((el, index) => {
      if (el[0] === orderName) {
        sorted.push(el) // I'm not happy about this push
        tempObjOfArr.splice(index, 1)
      }
      return undefined
    })
    return undefined
  })

  return sorted
}

export const hexToRgb = (hex, transparency = 0) => {
  let r
  let g
  let b = 0
  let hexTransp = false

  // 3 hex digits f.e. ('#0fa')
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
    // 6 hex digits f.e. ('#00ffca')
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
    // 8 hex digits with hex transparency - 2 last digits f.e. ('#00ffca80')
  } else if (hex.length === 9) {
    r = parseInt(hex[1] + hex[2], 16)
    g = parseInt(hex[3] + hex[4], 16)
    b = parseInt(hex[5] + hex[6], 16)
    hexTransp = Math.round((parseInt(hex[7] + hex[8], 16) / 256) * 10) / 10 // to get floating-point number from 0.0 to 1
  } else {
    return null // return null when the hex format has an incorrect number of characters
  }

  return transparency === 0
    ? hexTransp
      ? `rgba(${r}, ${g}, ${b}, ${hexTransp})` // 9 hex digits with hex transparency - 2 last digits ( f.e. '#00ffca80')
      : `rgb(${r}, ${g}, ${b})` // 3 or 6 hex digits ( f.e. '#0fa' / '#00ffca')
    : `rgba(${r}, ${g}, ${b}, ${transparency})` // 3 or 6 hex digits ( f.e. '#00ffca') with transparency
}

export const ATTACHMENT_CONTEXT = {
  DEFAULT: 'DEFAULT',
  QUESTION: 'QUESTION',
  ANSWER: 'ANSWER',
  START_PAGE: 'START_PAGE',
  CONFIRMATION: 'CONFIRMATION',
  OPT_IN: 'OPT_IN',
  TITLE: 'TITLE',
  LICENSE_KEY: 'LICENSE_KEY'
}

export const scrollToId = id => {
  setTimeout(() => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 500)
}

const questionTypesAlwaysNextButton = [
  QUESTION_TYPES.CHECKBOX,
  QUESTION_TYPES.TEXT,
  QUESTION_TYPES.FILE
]

const requiredOnly = [QUESTION_TYPES.GROUPED, QUESTION_TYPES.DATE]

const requiredAndAnswers = [
  QUESTION_TYPES.BUTTON,
  QUESTION_TYPES.MULTIPLE,
  QUESTION_TYPES.VALUATION
]
const requiredAnswersAndCustom = [QUESTION_TYPES.RADIO]

export const showNextButton = question => {
  if (question) {
    if (
      questionTypesAlwaysNextButton.includes(question.type) ||
      question?.isMultiple
    ) {
      return true
    }

    if (requiredOnly.includes(question.type)) {
      return !question?.required
    }

    if (requiredAndAnswers.includes(question.type)) {
      return (
        !question?.required ||
        question?.answers?.length === 0 ||
        question?.isMultiple
      )
    }

    if (requiredAnswersAndCustom.includes(question.type)) {
      return (
        !question?.required ||
        question?.answers?.length === 0 ||
        question?.customAnswerAllowed
      )
    }
  } else {
    return true
  }
}
